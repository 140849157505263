import React, { useState, useContext, Fragment, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Chip,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DiscussionComment from "./DiscussionComment";
import AuthContext from "../../store/auth-context";
import ResolveIssue from "./ResolveIssue";
import { timestampToDate, timestampMsToDate } from "../../lib/utils";
import { addDiscussionComment, db } from "../../lib/firestore";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { feedbackIssueTitle, feedbackIssueDescription } from "../../lib/utils";

const IssueDiscussion = (props) => {
  const routeParams = useParams();
  const [commentInput, setCommentInput] = useState("");
  const [allComments, setAllComments] = useState();

  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const organisation = authCtx.organisation;

  useEffect(() => {
    const commentsCollectionRef = collection(
      db,
      "organisations",
      organisation,
      "projects",
      routeParams.projectId,
      "feedback",
      props.issue.timestamp.toString(),
      "discussion"
    );
    const q = query(commentsCollectionRef, orderBy("timestamp"));
    onSnapshot(q, (snapshot) => {
      console.log("Fetched comments.");
      setAllComments(snapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  const issueStatus =
    commentInput && commentInput.includes("/resolve") ? false : true;

  const addCommentHandler = async () => {
    setIsLoading(true);
    if (commentInput) {
      await addDiscussionComment(
        organisation,
        routeParams.projectId,
        props.issue.timestamp,
        {
          author: authCtx.currentUser.displayName,
          comment: commentInput,
        },
        issueStatus
      );
      setValidationMessage("Comment saved.");
      setCommentInput("");
    } else {
      setValidationMessage("Comment can't be empty.");
    }
    setTimeout(() => setValidationMessage(""), 2000);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Typography variant="h5" color="secondary.main">
        {`#${routeParams.issueId} ${feedbackIssueTitle(props.issue.feedback_type, props.issue.user_response)}`}
      </Typography>
      <Stack direction="row" spacing={3}>
        <Chip
          label={props.issue.open ? "open" : "closed"}
          variant={props.issue.open ? "outlined" : ""}
          color={props.issue.open ? "success" : "primary"}
        />
        <Typography color="grey.500">
          {`Issue opened on ${timestampToDate(props.issue.timestamp)} by ${
            props.issue.created_by.displayName
          }`}
        </Typography>
      </Stack>
      <br />
      <br />
      <DiscussionComment
        comment={feedbackIssueDescription(props.issue.feedback_type, props.issue.user_response)}
        date={timestampToDate(props.issue.timestamp)}
        author={props.issue.created_by.displayName}
      />
      {allComments?.map((comment, index) => {
        return (
          <DiscussionComment
            key={index}
            comment={comment.comment}
            date={timestampMsToDate(comment.timestamp)}
            author={comment.author}
            trubricRunIds={props.trubricRunIds}
          />
        );
      })}
      <TextField
        id="outlined-textarea"
        label={props.issue.open ? "Add a comment" : "Add a comment to reopen"}
        variant="outlined"
        value={commentInput}
        onChange={(value) => {
          setCommentInput(value.target.value);
        }}
        multiline
        fullWidth
        rows={4}
        sx={{
          mt: 3,
          "& fieldset": {
            borderRadius: "16px",
          },
        }}
      />
      <Box
        mt={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <ResolveIssue clickHandler={addCommentHandler} open={issueStatus} />
        <Box>
          <Typography color="secondary.main">{validationMessage}</Typography>
          <br />
          {isLoading && <CircularProgress />}
        </Box>
      </Box>
    </Fragment>
  );
};

export default IssueDiscussion;

export const timestampToDate = (rawDate) => {
  // timestamp with seconds to a date
  const date = new Date(rawDate * 1000);
  return date.toLocaleString();
};

export const timestampMsToDate = (rawDate) => {
  // timestamp with milliseconds to a date
  const date = new Date(rawDate);
  return date.toLocaleString();
};

export const validationsPassedPercentage = (passed, failed) => {
  return 100 * (passed / (passed + failed)).toFixed(3)
}

export const feedbackIssueTitle = (feedback_type, user_response) => {
  if (feedback_type === "issue") {
    return Object.keys(user_response)[0]
  } else if (["thumbs", "faces"].includes(feedback_type)) {
    if (Object.values(user_response).length === 1) {
      return `User satisfaction: ${feedback_type}`
    } else {
      return `User satisfaction: ${feedback_type}, with open response`
    }
  } else if (feedback_type === "custom") {
    return "custom feedback"
  } else {
    return "feedback type not recognised"
  }
}

export const feedbackIssueDescription = (feedback_type, user_response) => {
  if (feedback_type === "issue") {
    return Object.values(user_response)[0]
  } else if (["thumbs", "faces"].includes(feedback_type)) {
    if (Object.values(user_response).length === 1) {
      return user_response[`User satisfaction: ${feedback_type}`]
    } else {
      return JSON.stringify(user_response, null, 2)
    }
  } else if (feedback_type === "custom") {
    return JSON.stringify(user_response, null, 2)
  } else {
    return "feedback type not recognised"
  }
}

import React, { useState } from "react";
import { Link } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { timestampToDate } from "../../lib/utils.js";

import { feedbackIssueTitle } from "../../lib/utils.js";

export default function FeedbackIssuesTable(props) {
  const [pageSize, setPageSize] = useState(10);
  const routeParams = useParams();
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 10,
      renderCell: (params) => (
        <Link
          component={RouterLink}
          to={`/projects/${
            routeParams.projectId
          }/feedback/${params.value.replace("#", "")}`}
          color="secondary.main"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "title",
      headerName: "Feedback Issues",
      width: 700,
      renderCell: (params) => (
        <Link
          component={RouterLink}
          to={`/projects/${routeParams.projectId}/feedback/${params.id.replace(
            "#",
            ""
          )}`}
          color="secondary.main"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      renderCell: (params) => (timestampToDate(params.value)),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
    },
  ];
  const rows = props.issues?.map((prod) => {
    return {
      id: prod.id,
      title: feedbackIssueTitle(prod.feedback_type, prod.user_response),
      createdOn: prod.timestamp,
      createdBy: prod.created_by.displayName,
    };
  });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdOn", sort: "desc" }],
        },
      }}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50]}
      checkboxSelection
      disableSelectionOnClick
      sx={{ mt: 1, borderRadius: 1 }}
      autoHeight
      rowHeight={37}
    />
  );
}

import React, { useState } from "react";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { timestampToDate } from "../../lib/utils.js";

import { validationsPassedPercentage } from "../../lib/utils.js";

export default function TrubricRunTable(props) {
  const [pageSize, setPageSize] = useState(10);
  const routeParams = useParams();

  const columns = [
    {
      field: "id",
      headerName: "Run",
      width: 180,
      renderCell: (params) => (
        <Link
          component={RouterLink}
          to={`/projects/${routeParams.projectId}/validations/${params.value}`}
        >
          {timestampToDate(params.value)}
        </Link>
      ),
    },
    {
      field: "validationsPassed",
      headerName: "Validations Passed",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return <Typography
          color={params.value === "100%" ? "success.main" : "error.main"}
        >
          {params.value}
        </Typography>
      },
    },
    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return params.value.length > 0 ? (
          <Chip
            label={params.value}
            size="small"
            variant="outlined"
            color="secondary"
          />
        ) : params.value;
      },
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      editable: true,
    },
    {
      field: "trubric",
      headerName: "Trubric",
      flex: 1,
      editable: true,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      editable: true,
    },
    {
      field: "modelVersion",
      headerName: "Model Version",
      flex: 1,
      editable: true,
    },
    {
      field: "dataContext",
      headerName: "Data Context",
      flex: 1,
      editable: true,
    },
    {
      field: "dataContextVersion",
      headerName: "Data Context Version",
      flex: 1,
      editable: true,
    },
  ];

  const rows = props.trubrics?.map((prod) => {
    return {
      id: prod.timestamp,
      validationsPassed: `${validationsPassedPercentage(prod.total_passed, prod.total_failed)}%`,
      tag: prod.tags,
      user: prod.run_by.displayName,
      trubric: prod.name,
      model: prod.model_name,
      modelVersion: prod.model_version,
      dataContext: prod.data_context_name,
      dataContextVersion: prod.data_context_version,
    };
  });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "desc" }],
        },
      }}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 20, 50]}
      checkboxSelection
      disableSelectionOnClick
      sx={{ mt: 2 }}
      autoHeight
      rowHeight={37}
    />
  );
}

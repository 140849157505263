import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";

const DiscussionComment = (props) => {
  const routeParams = useParams();
  const getLink = (comment) => {
    const replacedStrings = comment.split(" ").map((word) => {
      word = word.replace("#", "");
      if (props.trubricRunIds?.includes(parseInt(word))) {
        return (
          <Link
            component={RouterLink}
            to={`/projects/${routeParams.projectId}/validations/${word}`}
          >
            <Typography display="inline" px="2px">
              {"#" + word}
            </Typography>
          </Link>
        );
      } else {
        return (
          <Typography display="inline" px="2px">
            {word}
          </Typography>
        );
      }
    });
    return replacedStrings;
  };

  return (
    <Fragment>
      <Box
        border={1}
        borderColor="grey.200"
        backgroundColor="grey.200"
        borderRadius="2px 16px 16px 16px"
        color="grey.700"
        p={2}
      >
        <pre>
          {getLink(props.comment)}
        </pre>
      </Box>
      <Typography color="grey.700" align="right" fontSize="12px" px={2}>
        {`${props.date}, ${props.author}`}
      </Typography>
      <br />
    </Fragment>
  );
};

export default DiscussionComment;
